export default function recommendationsReducer(state = {}, action) {
	switch (action.type) {
		case "GET_ALL_RECOMMENDATIONS":
			return { ...state, allRecommendations: action.payload };
		case "SAVE_FILTERS":
			return { ...state, saveFilters: action.payload };
		case "SELECTED_RECOMMENDATION":
			return { ...state, selectedRecommendation: action.payload };
		case "RECOMMENDATION_ACTIONED_AND_ACTIVE_DATA":
			return { ...state, actionedAndActiveData: action.payload };
		case "GET_ALL_PRIORITIES":
			return { ...state, priorities: action.payload };

		default:
			return state;
	}
}
