import React, { useState, useEffect } from "react";
import "./Subscriptions.scss";
import { useOktaAuth } from "@okta/okta-react";
import {
	getSubscriptionsForUser,
	getDetailsForAllStrategies,
	saveSubscriptionsForUser,
	updateStrategy,
	displayAllStrategy,
} from "../../actions";

import { bindActionCreators } from "redux";
import _get from "lodash/get";
import { useSelector, connect } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import { TickIcon } from "../../components/Icons";
import { ToastContainer, toast } from "react-toastify";
import { addCommonActivityCaptureDetail, getCookie } from "../../helper";
import SubscriptionFilters from "../../components/subscriptionFilter";

const Subscriptions = (props) => {
	const [subscriptions, setSubscriptions] = useState([]);
	const [loading, setLoading] = useState(true);
	const { authState, oktaAuth } = useOktaAuth();
	const [userInfo, setUserInfo] = useState(null);
	const [callFilterComponent, setCallFilterComponent] = useState("");
	const [listOfStrategies, setListOfStrategies] = useState([]);
	const [allStrategyDetails, setAllStrategyDetails] = useState([]);

	let { selectedGlobalFilters } = useSelector((state) => state.globalReducer);
	let { selectedStrategies } = useSelector((state) => state.strategyReducer);
	let { displayAll } = useSelector((state) => state.strategyReducer);
	let { actionedAndActiveData } = useSelector(
		(state) => state.recommendationsReducer
	);
	useEffect(() => {
		document.title = `Subscriptions`;
		addCommonActivityCaptureDetail({
			page: "Subscriptions",
			globalFilters: selectedGlobalFilters,
			strategyFilters: selectedStrategies,
		});
		// We bring the strategies from here, dont ask why it works... it just does
		props
			.getDetailsForAllStrategiesAPI({
				projectName: "Walmart",
				strategyStatus: "active",
			})
			.then((response) => {
				// setAllStrategiesData(response.data);
			})
			.catch((error) => {
				console.error("Error fetching strategies:", error);
			});
		props
			.displayAllStrategyAPI()
			.then((response) => {
				setAllStrategyDetails(response.data);
			})
			.catch((error) => {
				console.error("Error fetching strategies:", error);
			});
	}, []);
	// useEffect(() => {
	// 	if (props.allStrategyDetails) {
	// 		const { allStrategyDetails } = props;
	// 		let arr = [];
	// 		allStrategyDetails.map((strategy, index) => {
	// 			arr.push(strategy.strategyName);
	// 		});
	// 		setListOfStrategies(arr);
	// 	}
	// }, [props.allStrategyDetails]);
	useEffect(() => {
		let arr = [];
		allStrategyDetails.map((strategy, index) => {
			arr.push(strategy.strategyName);
		});
		setListOfStrategies(arr);
	}, [allStrategyDetails]);
	useEffect(() => {
		if (props.userSubscriptions && props.userSubscriptions.length) {
			props.userSubscriptions.forEach((subscription) => {
				let allFilterValues = props.globalFilter.filterValues;
				let filterObject = subscription.filters;
				let alertName = subscription.recommendationType
					? subscription.recommendationType
							.toLowerCase()
							.replace(/_/g, "-")
							.replace(/ /g, "-")
					: "";
				let selectedParentFilter = [];
				let selectedFilterName = getCookie(
					`selected-filter-name-${alertName}`
				)
					? getCookie(`selected-filter-name-${alertName}`)
					: [];
				let selectedFilterObj = getCookie(
					`selected-filter-object-${alertName}`
				)
					? getCookie(`selected-filter-object-${alertName}`)
					: [];
				Object.keys(filterObject).forEach((filterName1) => {
					if (
						filterObject[filterName1] &&
						filterObject[filterName1].length &&
						allFilterValues &&
						allFilterValues[filterName1] &&
						allFilterValues[filterName1].length
					) {
						if (!selectedFilterName.includes(filterName1)) {
							selectedFilterName.push(filterName1);
						}
						document.cookie = `selected-filter-name-${alertName}=${JSON.stringify(
							selectedFilterName
						)};path=/;max-age=28800;`;
						allFilterValues[filterName1].forEach(
							(filterValue, index) => {
								if (
									filterObject[filterName1].includes(
										filterValue.value
									)
								) {
									selectedFilterObj.push({
										val: filterValue.value,
										filterName: filterName1,
										parent: filterValue.parents,
									});
									document.cookie = `selected-filter-object-${alertName}=${JSON.stringify(
										selectedFilterObj
									)};path=/;max-age=28800;`;
									selectedParentFilter = [];
									let filteredObj = selectedFilterObj.filter(
										(filterVal1) =>
											filterVal1.filterName ===
											filterName1
									);
									filteredObj.forEach((values) => {
										selectedParentFilter =
											selectedParentFilter.concat(
												values.parent
											);
									});
									document.cookie = `selected-parent-filter-${alertName}=${JSON.stringify(
										selectedParentFilter
									)};path=/;max-age=28800;`;
								}
							}
						);
					}
				});
			});
		}
	});
	useEffect(() => {
		if (userInfo) {
			Promise.all([
				props.getSubscriptionsForUserAPI(userInfo.email),
			]).then(function (res) {
				if (
					res[0].data.responseData &&
					res[0].data.responseData.subscriptions
				) {
					setSubscriptions(res[0].data.responseData.subscriptions);
				}
				setLoading(false);
			});
		}
	}, [userInfo]);
	useEffect(() => {
		if (!authState.isAuthenticated) {
			// When user isn't authenticated, forget any user info
			setUserInfo(null);
		} else {
			oktaAuth.getUser().then((info) => {
				setUserInfo(info);
			});
		}
	}, [authState, oktaAuth]); // Update if authState changes

	const handleOnUpdateStrategy = (strategyName, status) => {
		const availableStrategies = allStrategyDetails;
		// We have to set as active or unactive the strategies selected and updated reliying on which we want to see.
		// We would select as active the ones we want to see, and unactive the ones we dont want to see.
		const selectedStategy = availableStrategies.find(
			(strategy) => strategy.strategyName === strategyName
		);
		if (!selectedStategy) {
			console.log("not strategy found");
			return;
		}
		// updateStrategyAPI
		// We bring the strategies from here, dont ask why it works... it just does

		props
			.updateStrategyAPI({
				strategyId: selectedStategy.strategyId,
				strategyName: strategyName,
				strategyDesc: selectedStategy.strategyDesc,
				strategyUrl: selectedStategy.strategyUrl,
				strategyStatus: status, //"Active", //or Inactive?)...
				strategyReportId: selectedStategy.strategyReportId,
			})
			.then((response) => {
				console.log("response good for update of strat", response);
			})
			.catch((error) => {
				console.error("Error updating strategies:", error);
			});
	};

	const handleSusbscribe = (selectedItem) => {
		let itemExists = false;
		for (let i = 0; i < subscriptions.length; i++) {
			const currentItem = subscriptions[i].recommendationType;
			// if true, then uncheck the checkbox and remove the item from the list, also update as inactive the strategy
			if (currentItem === selectedItem) {
				itemExists = true;
				handleUnsubscribeStrategy(i);
				break;
			}
		}
		if (!itemExists) {
			let arr = cloneDeep(subscriptions);
			let obj = {
				recommendationType: selectedItem,
				filters: props.globalFilter.subscriptionFilters,
			};
			arr.push(obj);

			setSubscriptions(arr);
		}
	};
	const renderListOfSubscriptions = () => {
		return listOfStrategies.map((item, index) => {
			let arr = subscriptions.filter((subscription) => {
				return subscription.recommendationType === item;
			});
			let exists = arr.length > 0 ? true : false;
			if (exists) {
				return (
					<div key={index}>
						<div
							key={index}
							className='option option_hover flex'
							style={{
								justifyContent: "flex-start",
								gap: "0.5rem",
							}}
							onClick={(e) => {
								handleSusbscribe(item);
							}}
						>
							<input
								className='option option_hover'
								type='checkbox'
								checked={exists}
							/>
							<span className='selected_option'>{item}</span>
							<span className='user_option'>Configure</span>
							{/* <span className='selected_option'>
								{" "}
								<TickIcon />
							</span> */}
						</div>
					</div>
				);
			} else {
				return (
					<div key={index}>
						<div
							key={index}
							style={{
								justifyContent: "flex-start",
								gap: "0.5rem",
							}}
							className='option option_hover flex'
							onClick={(e) => {
								handleSusbscribe(item);
							}}
						>
							<input type='checkbox' checked={exists} />
							<span className='unselected_option'>{item}</span>
							<span className='user_option'>Configure</span>
						</div>
					</div>
				);
			}
		});
	};
	const renderListOfSubscribed = () => {
		return subscriptions.map((subscription, index) => {
			return <>{renderSubscriptionCard(subscription, index)}</>;
		});
	};
	function handleUnsubscribeStrategy(index) {
		let subscriptionState = cloneDeep(subscriptions);
		subscriptionState.splice(index, 1);
		setSubscriptions(subscriptionState);
	}
	function renderSubscriptionCard(subscription, index) {
		const { filters } = subscription;
		let count = 0;
		Object.keys(filters).map((key) => {
			count += filters[key].length;
		});
		let filterObj =
			count > 0 ? filters : props.globalFilter.subscriptionFilters;
		let alertName = subscription.recommendationType
			? subscription.recommendationType
					.toLowerCase()
					.replace(/_/g, "-")
					.replace(/ /g, "-")
			: "";
		let filterObject = getCookie(`selected-global-filters-${alertName}`)
			? getCookie(`selected-global-filters-${alertName}`)
			: filterObj;

		return (
			<div className='subscribed'>
				<div
					onClick={(e) => {
						onClickFilter(subscription.recommendationType);
					}}
				>
					{
						<SubscriptionFilters
							title={subscription.recommendationType}
							handleUnsubscribeStrategy={
								handleUnsubscribeStrategy
							}
							strategyIndex={index}
							displayUnsubscribeButton={true}
							getFilteredResults={getFilteredResults}
							subscriptionFilterValues={filterObject}
							loadFilterComp={callFilterComponent}
						/>
					}
				</div>
				<div className='horizontal_divider my-2 '></div>
			</div>
		);
	}

	function onClickFilter(alertName) {
		console.log("alertName: ", alertName);
		setCallFilterComponent(alertName);
	}

	function getFilteredResults(index, obj) {
		let subscriptionObj = cloneDeep(subscriptions);
		let subscriptionItem = subscriptionObj[index];
		if (subscriptionItem) {
			subscriptionItem.filters = obj;
		}
		setSubscriptions(subscriptionObj);
	}

	function handleSave() {
		console.log("subscriptions subs... ", subscriptions);
		const hasSubscriptions = subscriptions.length > 0;
		const getRecommendationTypes = subscriptions.map(
			(subscription) => subscription.recommendationType
		);

		const recommendationTypesArray = hasSubscriptions
			? getRecommendationTypes
			: [];
		// Improve this from the backend so we dont do this in the frontend (shot several requests instead of one)
		for (let i = 0; i < allStrategyDetails.length; i++) {
			const currentStategyName = allStrategyDetails[i].strategyName;
			console.log("recommendationTypesArray ", recommendationTypesArray);
			if (recommendationTypesArray.includes(currentStategyName)) {
				handleOnUpdateStrategy(currentStategyName, "Active");
			} else {
				handleOnUpdateStrategy(currentStategyName, "Inactive");
			}
		}

		let countryName = props.subproject.includes("-")
			? props.subproject.split("-").pop()
			: "";
		let obj = {
			user: userInfo.email,
			frequency: "daily",
			subscriptionData: subscriptions,
			subscriptionStatus:
				subscriptions.length > 0 ? "active" : "inactive",
			UserName: userInfo?.name,
			UserID: userInfo?.sAMAccountName,
			AlertName: recommendationTypesArray,
			SubProjectName: props.subproject,
			Country: countryName,
			customerKey: props.customerKey,
		};
		console.log("obj: ", obj);
		// allStrategiesData
		// 1) get strategies from strateg/getStatusBasedStrategies. Done
		// 2) check if the strategy is in the subscription list
		// 3) if it is, then add it to the subscription object
		// 4) if it is not, do nothing.
		// 5) save the subscription object, send the email and save the subscription object in the database
		props.saveSubscriptionsForUserAPI(obj, (res, rej) => {
			if (res) {
				toast.success(res.data.responseDesc, {
					position: toast.POSITION.BOTTOM_LEFT,
				});
				props
					.displayAllStrategyAPI()
					.then((response) => {
						setAllStrategyDetails(response.data);
					})
					.catch((error) => {
						console.error("Error fetching strategies:", error);
					});
			}
		});
	}

	return (
		<div id='subscription_wrapper'>
			<ToastContainer />

			{/* main content starts */}
			<div className='container-fluid  default-top  bg-gray p-5'>
				<div className='row'>
					<div className='col-12 n-padding d-flex justify-content-between'>
						<div>
							<div className='header'>Configurations</div>
							<div>
								Configuration email will be generated at the end
								of each working day.
							</div>
						</div>
						<div>
							<button
								className='btn btn-apply'
								onClick={handleSave}
							>
								Save Changes
								<TickIcon />
							</button>
						</div>
					</div>
				</div>
				{/* recommenation subscription */}
				<div className='row my-5'>
					<div className='col-12'>
						<div className='row'>
							<div className='col-3'>
								<h3>All Strategies/Recommendations</h3>
								<div className='list_wrapper'>
									{renderListOfSubscriptions()}
								</div>
							</div>
							<div className='col-9'>
								<h3>Your Configurations </h3>
								<div className='list_wrapper'>
									{renderListOfSubscribed()}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
function mapStateToProps(state) {
	let userSubscriptions = _get(
		state,
		"subscriptionReducer.getUserSubscription.responseData.subscriptions",
		[]
	);
	let saveUserSubscription = _get(
		state,
		"subscriptionReducer.saveUserSubscription.responseData",
		[]
	);
	// let allStrategyDetails = _get(
	// 	state,
	// 	"strategyReducer.detailsForAllStrategies",
	// 	[]
	// );

	let globalFilter = _get(state, "globalReducer.globalFilter.data", []);
	let subproject = _get(state, "globalReducer.subproject", "");
	let customerKey = _get(state, "globalReducer.selectedCustomerKey", false);
	return {
		userSubscriptions: userSubscriptions,
		// allStrategyDetails: allStrategyDetails,
		saveUserSubscription: saveUserSubscription,
		globalFilter,
		subproject,
		customerKey,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			getSubscriptionsForUserAPI: getSubscriptionsForUser,
			getDetailsForAllStrategiesAPI: getDetailsForAllStrategies,
			saveSubscriptionsForUserAPI: saveSubscriptionsForUser,
			updateStrategyAPI: updateStrategy,
			displayAllStrategyAPI: displayAllStrategy,
		},
		dispatch
	);
}
export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);
